import React, { createContext, useContext, useEffect, useState } from 'react';
import { RolesTypes } from '../commons/constants';
import authenticationService from '../services/authentication.service';
import userService from '../services/user.service';

const noop = () => {};

const AuthContext = createContext({ user: null, login: noop, logout: noop, type: null, flags: null });

const AuthenticationProvider = (props) => {
  const [user, setUser] = useState(null);
  const [flags, setFlags] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (user) => {
    setUser(user);
    setIsAuthenticated(true);
  };
  const feature = (data) => {
    setFlags(data);
  };

  const logout = () => {
    authenticationService.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const authUser = authenticationService.getAuthUser();
    if (authUser !== null) {
      setUser(authUser);
      setIsAuthenticated(true);
    }
  }, [user]);

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated, flags }} {...props} />;
};

const loginUser = ({ client, email, password, auth }) => {
  if (client) {
    return authenticationService.clientSignIn(email, password).then((user) => user);
  } else {
    return authenticationService.signIn(email, password).then((user) => user);
  }
};

const getUser = async ({ user }) => {
  if (user.role === RolesTypes.company) {
    return userService.getUser(user.id);
  } else {
    return userService.getClientUser(user.id);
  }
};

export default AuthenticationProvider;

export const useAuthentication = () => useContext(AuthContext);
export { getUser, loginUser };
