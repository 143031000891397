import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { loginUser, useAuthentication } from '../session/AuthenticationProvider';

function Login({ history, client }) {
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuthentication();

  const login = async (e) => {
    e.preventDefault();
    try {
      const user = await loginUser({ client, email, password });
      auth.login(user);
      history.push('/');
    } catch (err) {
      let response = err.response ? err.response : err;
      let msg = response && response.data ? response.data : response;
      setError(t(`error.${msg}`));
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form onSubmit={login}>
                    {error && <Alert color="danger">{error}</Alert>}
                    <h1>{t('login')}</h1>
                    <p className="text-muted">{t('loginMessage')}</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="email" name="email" placeholder={t('emailPlaceholder')} required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder={t('passwordPlaceholder')} required value={password} onChange={(e) => setPassword(e.target.value)} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4">
                          {t('login')}
                        </Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Link to="/password-reset">
                          <Button color="link" className="px-0">
                            {t('forgotPassword')}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CardBody className="text-center">
                  {client && (
                    <div>
                      <h2>{t('welcome.intro')}</h2>
                      <p>{t('welcome.title')}</p>
                    </div>
                  )}
                  {!client && (
                    <div>
                      <h2>{t('register')}</h2>
                      <p>{t('registerText')}</p>
                      <Link to="/register">
                        <Button color="primary" className="mt-3" active tabIndex={-1}>
                          {t('registerNow')}
                        </Button>
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
