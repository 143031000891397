import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import common_en from './locales/en/common';
import common_es from './locales/es/common';

i18n
  .use(detector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en', // use en if detected lng is not available
    resources: {
      es: {
        translation: common_es,
      },
      en: {
        translation: common_en,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
