export const libraryFilesState = {
  revisionAccepted: 'revisionAccepted',
};

export const RolesTypes = {
  client: 'client',
  company: 'user',
};

export const ComplaintTypeEnum = {
  queja: '1',
  tnc: '2',
};

export const ExtraPermissions = {
  complaint_manager: 'complaint_manager',
};
export const statusOptions = [
  {
    name: 'Cancelado',
    value: 'cancelled',
  },
  {
    name: 'Cerrado',
    value: 'closed',
  },
  {
    name: 'Finalizado',
    value: 'finished',
  },
  {
    name: 'Pendiente',
    value: 'pending',
  },
  {
    name: 'En progreso',
    value: 'inProgress',
  },
  {
    name: 'Devuelto',
    value: 'returned',
  },
];

export const complaintType = [
  {
    id: 1,
    name: 'Queja',
    letter: 'Q',
  },
  {
    id: 2,
    name: 'Trabajo no conforme',
    letter: 'TNC',
  },
];
