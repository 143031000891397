import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '../../users/Login';
import { useAuthentication } from '../../session/AuthenticationProvider';

export function PrivateRoute({ component, ...options }) {
  const { isAuthenticated } = useAuthentication();
  //const isAuthenticated = true

  return isAuthenticated ? <Route {...options} component={component} /> : <LoginPage />;
}

export default PrivateRoute;
